import { HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject, makeStateKey, TransferState } from '@angular/core';
import { of, tap } from 'rxjs';

export const transferStateInterceptor: HttpInterceptorFn = (req, next) => {
  const transferState = inject(TransferState);
  const key = makeStateKey<any>(normalizeUrl(req.urlWithParams));

  if (transferState.hasKey(key)) {
    const cachedData = transferState.get(key, null);
    transferState.remove(key);
    return of(new HttpResponse({ body: cachedData }));
  }

  return next(req).pipe(
    tap(event => {
      if (event instanceof HttpResponse) {
        transferState.set(key, event.body);
      }
    })
  );
};

function normalizeUrl(url: string): string {
  const baseUrlsToRemove = [
    'http://localhost:8080/cache/configs/',
    'https://apis-dev.cocha.cloud/configs/',
    'https://apis-qa.cocha.cloud/configs/',
    'https://apis-prod.cocha.cloud/configs/',
    'http://localhost:8080/cache/api-admin-config/configs/',
    'https://apis-dev.cocha.cloud/api-admin-config/configs/',
    'https://apis-qa.cocha.cloud/api-admin-config/configs/',
    'https://apis-prod.cocha.cloud/api-admin-config/configs/',
    'http://api-landings.api-landings:8080/get-admin/',
    'https://apis-dev.cocha.cloud/landings/',
    'https://apis-qa.cocha.cloud/landings/',
    'https://apis-prod.cocha.cloud/landings/',
  ];

  for (const baseUrl of baseUrlsToRemove) {
    if (url.startsWith(baseUrl)) {
      url = url.replace(baseUrl, '');
      break;
    }
  }

  return url;
}
